import { FC } from 'react';
import styles from './review-body.module.scss';

interface Props {
  name: string;
  grade: string;
  text: string;
}

export const ReviewBody: FC<Props> = ({ name, grade, text }) => {
  return (
    <div className={styles.module}>
      <span className={styles.name}>{name}</span>
      <span className={styles.grade}>{grade}</span>
      <p className={styles.text}>{text}</p>
    </div>
  );
};
