import { FC, RefObject, useEffect, useRef } from 'react';
import DayPicker, { DayModifiers } from 'react-day-picker';
import { getElementOffset } from '../../../../utils';
import styles from './calendar.module.scss';
import { localization } from './localization';

interface Props {
  labelRef: RefObject<HTMLLabelElement>;
  onDayClick: (day: Date) => void;
  onOutsideClick: () => void;
  disabledDaysBefore: Date;
}

export const Calendar: FC<Props> = ({
  labelRef,
  onDayClick,
  onOutsideClick,
  disabledDaysBefore,
}) => {
  const calendarRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const handleClick = (event: Event) => {
      if (!calendarRef.current || !labelRef.current) {
        return;
      }

      if (
        !calendarRef.current.contains(event.target as HTMLElement) &&
        !labelRef.current.contains(event.target as HTMLElement)
      ) {
        onOutsideClick();
      }
    };

    document.addEventListener('click', handleClick);

    return () => {
      document.removeEventListener('click', handleClick);
    };
  }, [labelRef, calendarRef, onOutsideClick]);

  const labelCoordinates = labelRef.current?.getBoundingClientRect();
  // const top = labelCoordinates
  //   ? labelCoordinates.top + labelCoordinates.height + window.pageYOffset
  //   : 0;
  // const left = labelCoordinates
  //   ? labelCoordinates.left + window.pageXOffset
  //   : 0;

  const { top, left } = getElementOffset(labelRef.current);

  return (
    <div
      className={styles.module}
      style={{ top: top + labelCoordinates!.height, left }}
      ref={calendarRef}
    >
      <DayPicker
        locale="ru"
        months={localization.months}
        weekdaysLong={localization.weekdaysLong}
        weekdaysShort={localization.weekdaysShort}
        labels={localization.labels}
        firstDayOfWeek={1}
        disabledDays={{ before: disabledDaysBefore }}
        onDayClick={(day: Date, modifiers: DayModifiers) => {
          if (modifiers.disabled) {
            return;
          }
          onDayClick(day);
        }}
      />
    </div>
  );
};
