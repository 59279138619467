import { BookingState, Action } from './types';

export const bookingReducer = (
  state: BookingState,
  action: Action
): BookingState => {
  switch (action.type) {
    case 'setArrival':
      return { ...state, arrival: action.arrival };
    case 'setDeparture':
      return { ...state, departure: action.departure };
    case 'setAdults':
      return { ...state, adults: action.adults };
    case 'setChildren':
      if (action.children > state.children.length) {
        return {
          ...state,
          children: [
            ...state.children,
            ...Array(action.children - state.children.length).fill(0),
          ],
        };
      } else if (action.children < state.children.length) {
        return {
          ...state,
          children: state.children.filter((_, idx) => idx < action.children),
        };
      } else {
        return state;
      }
    case 'changeChild':
      return {
        ...state,
        children: state.children.map((item, idx) =>
          idx === action.idx ? action.value : item
        ),
      };
    case 'setName':
      return { ...state, name: action.name };
    case 'setPhone':
      return { ...state, phone: action.phone };

    case 'showModal':
      return {
        ...state,
        kind: action.kind,
        room: action.room,
        ui: { ...state.ui, modalIsShow: true },
      };
    case 'hideModal':
      return {
        ...state,
        kind: null,
        room: null,
        ui: { ...state.ui, modalIsShow: false },
      };
    case 'showArrival':
      return {
        ...state,
        ui: { ...state.ui, arrivalIsShow: true },
      };
    case 'hideArrival':
      return {
        ...state,
        ui: { ...state.ui, arrivalIsShow: false },
      };
    case 'toggleArrival':
      return {
        ...state,
        ui: { ...state.ui, arrivalIsShow: !state.ui.arrivalIsShow },
      };
    case 'showDeparture':
      return {
        ...state,
        ui: { ...state.ui, departureIsShow: true },
      };
    case 'hideDeparture':
      return {
        ...state,
        ui: { ...state.ui, departureIsShow: false },
      };
    case 'toggleDeparture':
      return {
        ...state,
        ui: { ...state.ui, departureIsShow: !state.ui.departureIsShow },
      };
    case 'showChildren':
      return {
        ...state,
        ui: { ...state.ui, childrenIsShow: true },
      };
    case 'hideChildren':
      return {
        ...state,
        ui: { ...state.ui, childrenIsShow: false },
      };
    case 'toggleChildren':
      return {
        ...state,
        ui: { ...state.ui, childrenIsShow: !state.ui.childrenIsShow },
      };
    case 'showThanks':
      return {
        ...state,
        ui: { ...state.ui, thanksIsShow: true },
      };
    case 'hideThanks':
      return {
        ...state,
        ui: { ...state.ui, thanksIsShow: false },
      };

    default:
      return state;
  }
};
