import { FC, useCallback, useContext, useState } from 'react';
import { TextualBody } from '../../textual/textual-body';
import { Input } from '../../_common/input';
import { Modal } from '../../_common/modal';
import { Policy } from '../../_common/policy';
import { QuizContext } from '../quiz-context';
import styles from './step-three.module.scss';

export const StepThree: FC = () => {
  const { state, dispatch } = useContext(QuizContext);

  const [isShowPolicy, setIsShowPolicy] = useState(false);

  return (
    <>
      <div className={styles.module}>
        <Input
          moduleClass={styles.name}
          placeholder="Ваше имя"
          value={state.name}
          onChange={(event) =>
            dispatch({ type: 'setName', name: event.target.value })
          }
        />
        <Input
          moduleClass={styles.phone}
          placeholder="Номер телефона"
          type="tel"
          pattern="\d+"
          required
          value={state.phone}
          onChange={(event) =>
            dispatch({ type: 'setPhone', phone: event.target.value })
          }
        />
        <Policy onButtonClick={() => setIsShowPolicy(true)} />
      </div>
      <Modal
        isShow={isShowPolicy}
        onClose={useCallback(() => setIsShowPolicy(false), [])}
      >
        <TextualBody type="policy" />
      </Modal>
    </>
  );
};
