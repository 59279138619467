import { FC, useCallback, useState } from 'react';
import { Modal } from '../_common/modal';
import { Modal2 } from '../_common/modal2';
import { Thanks } from '../_common/thanks';
import { AddReviewBody } from './add-review-body';
// import styles from './add-review.module.scss';

export const AddReview: FC = () => {
  const [isShow, setIsShow] = useState(false);
  const [isShowThanks, setIsShowThanks] = useState(false);

  const onSend = () => {
    setIsShow(false);
    setIsShowThanks(true);
  };

  return (
    <>
      <button className="reviews__add" onClick={() => setIsShow(true)}>
        Написать отзыв
      </button>
      <Modal isShow={isShow} onClose={useCallback(() => setIsShow(false), [])}>
        <AddReviewBody onSend={onSend} />
      </Modal>
      <Modal2
        isShow={isShowThanks}
        onClose={useCallback(() => setIsShowThanks(false), [])}
      >
        <Thanks />
      </Modal2>
    </>
  );
};
