import { FC } from 'react';
import cn from 'classnames';
import styles from './policy.module.scss';
import { Checkbox } from '../checkbox';

interface Props {
  onButtonClick: () => void;
  moduleClass?: string;
}

export const Policy: FC<Props> = ({ onButtonClick, moduleClass }) => {
  const moduleStyles = cn(styles.module, moduleClass);

  return (
    <div className={moduleStyles}>
      <Checkbox moduleClass={styles.checkbox} required />
      <p className={styles.text}>
        Даю согласие на обработку{' '}
        <span className={styles.button} onClick={onButtonClick}>
          персональных данных
        </span>
      </p>
    </div>
  );
};
