import { FC, useCallback, useState } from 'react';
import { Modal } from '../_common/modal';
import { Modal2 } from '../_common/modal2';
import { Thanks } from '../_common/thanks';
import { CallbackBody } from './callback-body';
// import styles from './header-callback.module.scss';

interface Props {
  type: 'header' | 'question' | 'footer';
}

export const Callback: FC<Props> = ({ type }) => {
  const [isShow, setIsShow] = useState(false);
  const [isShowThanks, setIsShowThanks] = useState(false);

  const onSend = () => {
    setIsShow(false);
    setIsShowThanks(true);
  };

  const typeClasses = {
    header: 'header__callback',
    question: 'question__button',
    footer: 'footer__callback',
  };

  return (
    <>
      <button className={typeClasses[type]} onClick={() => setIsShow(true)}>
        Заказать звонок
      </button>
      <Modal isShow={isShow} onClose={useCallback(() => setIsShow(false), [])}>
        <CallbackBody onSend={onSend} />
      </Modal>
      <Modal2
        isShow={isShowThanks}
        onClose={useCallback(() => setIsShowThanks(false), [])}
      >
        <Thanks />
      </Modal2>
    </>
  );
};
