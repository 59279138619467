import Swiper, { Navigation, Pagination } from 'swiper';
import GLightbox from 'glightbox';

Swiper.use([Navigation, Pagination]);

const initDetailButton = () => {
  const button = document.querySelector<HTMLButtonElement>('.about__detail');
  const block = document.querySelector<HTMLDivElement>('.about__block');

  button?.addEventListener('click', () =>
    block?.classList.add('about__block--active')
  );
};

const initSlider = () => {
  new Swiper('.about__slider .swiper-container', {
    loop: true,
    spaceBetween: 20,

    navigation: {
      nextEl: '.about__next',
      prevEl: '.about__prev',
    },

    pagination: {
      el: '.about__pagination',
    },

    breakpoints: {
      768: {
        slidesPerView: 2,
        spaceBetween: 30,
      },
      1200: {
        slidesPerView: 2,
        spaceBetween: 30,
      },
    },
  });
};

const initZoom = () => {
  GLightbox({
    selector: '.about__slide',
    loop: true,
  });
};

const about = {
  init: () => {
    initDetailButton();
    initSlider();
    initZoom();
  },
};

export default about;
