// import React from 'react';
// import ReactDOM from 'react-dom';
import './styles/main.scss';
// import App from './App';
// import reportWebVitals from './reportWebVitals';

// ReactDOM.render(
//   <React.StrictMode>
//     <App />
//   </React.StrictMode>,
//   document.getElementById('root')
// );

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();

import header from './scripts/header';
import screen from './scripts/screen';
import about from './scripts/about';
import services from './scripts/services';
import rooms from './scripts/rooms';
import question from './scripts/question';
import reviews from './scripts/reviews';
import calculation from './scripts/calculation';
import footer from './scripts/footer';

header.init();
screen.init();
about.init();
services.init();
rooms.init();
question.init();
reviews.init();
calculation.init();
footer.init();
