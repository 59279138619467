import { FC, RefObject, useEffect, useRef } from 'react';
import { getElementOffset } from '../../../../utils';
import { Select } from '../../select';
import styles from './dropdown.module.scss';
import { ageOptions } from './utils';

interface Props {
  faceRef: RefObject<HTMLDivElement>;
  children: number[];
  onOutsideClick: () => void;
  onChangeChild: (value: number, idx: number) => void;
  inQuiz?: boolean;
}

export const Dropdown: FC<Props> = ({
  faceRef,
  children,
  onOutsideClick,
  onChangeChild,
  inQuiz,
}) => {
  const dropdownRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const handleClick = (event: Event) => {
      if (!dropdownRef.current || !faceRef.current) {
        return;
      }

      if (
        !dropdownRef.current.contains(event.target as HTMLElement) &&
        !faceRef.current.contains(event.target as HTMLElement)
      ) {
        onOutsideClick();
      }
    };

    document.addEventListener('click', handleClick);

    return () => {
      document.removeEventListener('click', handleClick);
    };
  }, [faceRef, onOutsideClick]);

  const faceCoordinates = faceRef.current?.getBoundingClientRect();
  // const top = faceCoordinates
  //   ? faceCoordinates.top + faceCoordinates.height + window.pageYOffset
  //   : 0;
  // const left = faceCoordinates ? faceCoordinates.left + window.pageXOffset : 0;

  const { top, left } = getElementOffset(faceRef.current);

  return (
    <div
      className={styles.module}
      style={{
        top: top + faceCoordinates!.height,
        left: inQuiz ? left - faceCoordinates!.width : left,
        maxWidth: `${faceCoordinates!.width}px`,
      }}
      ref={dropdownRef}
    >
      {children.map((item, idx) => (
        <div className={styles.child} key={idx}>
          <span className={styles.name}>ребенок {idx + 1}</span>
          <Select
            moduleClass={styles.select}
            options={ageOptions}
            value={item}
            onChange={(event) => onChangeChild(+event.target.value, idx)}
            isMini
          />
        </div>
      ))}
      <button className={styles.button} onClick={() => onOutsideClick()}>
        Готово
      </button>
    </div>
  );
};
