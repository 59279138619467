import { FC } from 'react';
import styles from './thanks.module.scss';

export const Thanks: FC = () => {
  return (
    <div className={styles.module}>
      <img className={styles.icon} src="/images/thanks-icon.svg" alt="thanks" />
      <strong className={styles.title}>Спасибо!</strong>
      <p className={styles.text}>Мы свяжемся с вами в ближайшее время!</p>
    </div>
  );
};
