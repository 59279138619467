import { FC, useCallback, useState } from 'react';
import { Modal } from '../_common/modal';
import { TextualBody } from './textual-body';
// import styles from './textual.module.scss';

interface TextualState {
  isShow: boolean;
  type: 'policy' | 'rules' | 'cookie' | null;
}

export const Textual: FC = () => {
  const [state, setState] = useState<TextualState>({
    isShow: false,
    type: null,
  });

  return (
    <>
      <div className="footer__bottom">
        <button
          className="footer__button"
          onClick={() => setState((prev) => ({ isShow: true, type: 'policy' }))}
        >
          Политика конфидетеальности
        </button>
        <button
          className="footer__button"
          onClick={() => setState((prev) => ({ isShow: true, type: 'rules' }))}
        >
          Правила пользования
        </button>
        <button
          className="footer__button"
          onClick={() => setState((prev) => ({ isShow: true, type: 'cookie' }))}
        >
          Использование cookie
        </button>
      </div>
      <Modal
        isShow={state.isShow}
        onClose={useCallback(() => setState({ isShow: false, type: null }), [])}
      >
        {state.type ? <TextualBody type={state.type} /> : null}
      </Modal>
    </>
  );
};
