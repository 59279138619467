import React from 'react';
import ReactDOM from 'react-dom';
import { Callback } from '../components/callback';

import SmoothScroll from 'smooth-scroll';

const headerEl = document.querySelector('.header');
const headerButton = document.querySelector('.header__button');

const initMobileMenu = () => {
  headerButton?.addEventListener('click', () => {
    headerEl?.classList.toggle('header--open');
  });
};

const initNavButtons = () => {
  const buttons = document.querySelectorAll<HTMLAnchorElement>(
    '.header__nav a'
  );
  buttons.forEach((item) =>
    item.addEventListener('click', () => {
      headerEl!.classList.remove('header--open');
    })
  );
};

const initSmoothScroll = () => {
  new SmoothScroll('a[href*="#"]');
};

const initCallback = () => {
  ReactDOM.render(
    <React.StrictMode>
      <Callback type="header" />
    </React.StrictMode>,
    document.getElementById('header-callback')
  );
};

const header = {
  init: () => {
    initMobileMenu();
    initNavButtons();
    initSmoothScroll();
    initCallback();
  },
};

export default header;
