import React from 'react';
import ReactDOM from 'react-dom';

import { Quiz } from '../components/quiz';
import { QuizProvider } from '../components/quiz';

const initQuiz = () => {
  ReactDOM.render(
    <React.StrictMode>
      <QuizProvider>
        <Quiz />
      </QuizProvider>
    </React.StrictMode>,
    document.getElementById('quiz')
  );
};

const calculation = {
  init: () => {
    initQuiz();
  },
};

export default calculation;
