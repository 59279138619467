import { FC, useRef } from 'react';
import cn from 'classnames';
import styles from './date-picker.module.scss';
import { createPortal } from 'react-dom';
import { Calendar } from './calendar';

const portalEl = document.getElementById('portal');

interface Props {
  isOpen: boolean;
  onToggle: () => void;
  placeholder: string;
  value: Date | null;
  onDayClick: (day: Date) => void;
  disabledDaysBefore: Date;
  moduleClass?: string;
}

export const DatePicker: FC<Props> = ({
  isOpen,
  onToggle,
  placeholder,
  value,
  onDayClick,
  moduleClass,
  disabledDaysBefore,
}) => {
  const labelRef = useRef<HTMLLabelElement>(null);

  const moduleStyles = cn(styles.module, moduleClass);

  return (
    <>
      <label className={moduleStyles} onClick={onToggle} ref={labelRef}>
        <input
          readOnly
          className={styles.input}
          placeholder={placeholder}
          value={value ? value.toLocaleDateString() : ''}
        />
      </label>
      {portalEl &&
        isOpen &&
        createPortal(
          <Calendar
            labelRef={labelRef}
            onDayClick={onDayClick}
            onOutsideClick={() => onToggle()}
            disabledDaysBefore={disabledDaysBefore}
          />,
          portalEl
        )}
    </>
  );
};
