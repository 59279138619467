import { FC, useCallback, useContext } from 'react';
import { Modal } from '../_common/modal';
import { BookingContext } from './booking-context';
import { BookingBody } from './booking-body';
import { Thanks } from '../_common/thanks';
import { Modal2 } from '../_common/modal2';

interface Props {
  room?: string;
}

export const Booking: FC<Props> = ({ room = null }) => {
  const { state, dispatch } = useContext(BookingContext);

  return (
    <>
      <div className={`${room ? 'rooms' : 'screen'}__buttons`}>
        <button
          className={`${room ? 'rooms' : 'screen'}__booking`}
          onClick={() => dispatch({ type: 'showModal', kind: 'booking', room })}
        >
          Забронировать
        </button>
        <button
          className={`${room ? 'rooms' : 'screen'}__calculate`}
          onClick={() =>
            dispatch({ type: 'showModal', kind: 'calculate', room })
          }
        >
          Расчет стоимости
        </button>
      </div>
      <Modal
        isShow={state.ui.modalIsShow}
        onClose={useCallback(() => dispatch({ type: 'hideModal' }), [dispatch])}
      >
        <BookingBody kind={state.kind!} />
      </Modal>
      <Modal2
        isShow={state.ui.thanksIsShow}
        onClose={useCallback(() => dispatch({ type: 'hideThanks' }), [
          dispatch,
        ])}
      >
        <Thanks />
      </Modal2>
    </>
  );
};
