import { FC, FormEvent, useCallback, useState } from 'react';
import Slider from 'rc-slider';
import { Input } from '../../_common/input';
import styles from './add-review-body.module.scss';
import { Textarea } from '../../_common/textarea';
import { Policy } from '../../_common/policy';
import { TextualBody } from '../../textual/textual-body';
import { Modal2 } from '../../_common/modal2';

interface Props {
  onSend: () => void;
}

export const AddReviewBody: FC<Props> = ({ onSend }) => {
  const [grade, setGrade] = useState(10);
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [city, setCity] = useState('');
  const [text, setText] = useState('');

  const [isShowPolicy, setIsShowPolicy] = useState(false);

  const handleSubmit = async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    await fetch('/ajax/addReview.php', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ grade, name, email, city, text }),
    });

    onSend();
  };

  return (
    <>
      <div className={styles.module}>
        <h3 className={styles.title}>Оставить отзыв</h3>
        <form onSubmit={handleSubmit}>
          <div className={styles.grade}>
            <p className={styles.gradeTitle}>Ваша оценка</p>
            <Slider
              className={styles.gradeSlider}
              min={1}
              max={5}
              defaultValue={grade}
              marks={{
                1: 1,
                2: 2,
                3: 3,
                4: 4,
                5: 5,
              }}
              step={null}
              onChange={(value) => setGrade(value)}
            />
          </div>

          <Input
            moduleClass={styles.name}
            placeholder="Ваше имя"
            required
            value={name}
            onChange={(event) => setName(event.target.value)}
          />
          <Input
            moduleClass={styles.email}
            placeholder="E-mail"
            required
            value={email}
            onChange={(event) => setEmail(event.target.value)}
          />
          <Input
            moduleClass={styles.city}
            placeholder="Город"
            required
            value={city}
            onChange={(event) => setCity(event.target.value)}
          />
          <Textarea
            moduleClass={styles.text}
            placeholder="Ваш отзыв"
            required
            value={text}
            onChange={(event) => setText(event.target.value)}
          />
          <Policy
            moduleClass={styles.policy}
            onButtonClick={() => setIsShowPolicy(true)}
          />
          <button className={styles.confirm}>Отправить</button>
        </form>
      </div>
      <Modal2
        isShow={isShowPolicy}
        onClose={useCallback(() => setIsShowPolicy(false), [])}
      >
        <TextualBody type="policy" />
      </Modal2>
    </>
  );
};
