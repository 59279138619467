import React from 'react';
import ReactDOM from 'react-dom';

import Swiper, { Navigation, Pagination } from 'swiper';
import { AddReview } from '../components/add-review';
import { Review } from '../components/review';

Swiper.use([Navigation, Pagination]);

const initSlider = () => {
  new Swiper('.reviews__slider .swiper-container', {
    loop: true,
    spaceBetween: 20,

    navigation: {
      nextEl: '.reviews__next',
      prevEl: '.reviews__prev',
    },

    pagination: {
      el: '.reviews__pagination',
    },

    breakpoints: {
      768: {
        slidesPerView: 2,
        spaceBetween: 30,
      },
      1200: {
        slidesPerView: 3,
        spaceBetween: 30,
      },
    },
  });
};

const initReviewsHeight = () => {
  document.addEventListener('DOMContentLoaded', () => {
    const items = document.querySelectorAll<HTMLDivElement>('.reviews__slide');

    let height = 125;
    if (window.innerWidth >= 768) {
      height = 125;
    }

    items.forEach((item) => {
      const p = item.querySelector<HTMLParagraphElement>('.reviews__text');
      if (p!.offsetHeight > height) {
        item.classList.add('cut');
      }
    });
  });
};

const initDetail = () => {
  const detailItems = document.querySelectorAll<HTMLDivElement>(
    '.reviews-detail'
  );

  detailItems.forEach((item) =>
    ReactDOM.render(
      <React.StrictMode>
        <Review
          name={item.dataset.name!}
          grade={item.dataset.grade!}
          text={item.dataset.text!}
        />
      </React.StrictMode>,
      item
    )
  );
};

const initAddReview = () => {
  ReactDOM.render(
    <React.StrictMode>
      <AddReview />
    </React.StrictMode>,
    document.getElementById('reviews-add')
  );
};

const reviews = {
  init: () => {
    initSlider();
    initDetail();
    initAddReview();
    initReviewsHeight();
  },
};

export default reviews;
