import { FC, useState } from 'react';
import { Modal } from '../_common/modal';
import { ReviewBody } from './review-body';

interface Props {
  name: string;
  grade: string;
  text: string;
}

export const Review: FC<Props> = ({ name, grade, text }) => {
  const [isShow, setIsShow] = useState(false);

  return (
    <>
      <button className="reviews__button" onClick={() => setIsShow(true)}>
        Далее
      </button>
      <Modal isShow={isShow} onClose={() => setIsShow(false)}>
        <ReviewBody name={name} grade={grade} text={text} />
      </Modal>
    </>
  );
};
