import React from 'react';
import ReactDOM from 'react-dom';
import { Booking } from '../components/booking';
import { BookingProvider } from '../components/booking/booking-context';

import Swiper, { Navigation, Pagination } from 'swiper';
import GLightbox from 'glightbox';

Swiper.use([Navigation, Pagination]);

const initSliders = () => {
  const items = document.querySelectorAll<HTMLDivElement>('.rooms__item');

  items.forEach((item) => {
    const slider = item.querySelector<HTMLDivElement>('.swiper-container');
    const prev = item.querySelector<HTMLButtonElement>('.rooms__prev');
    const next = item.querySelector<HTMLButtonElement>('.rooms__next');
    const pagination = item.querySelector<HTMLDivElement>('.rooms__pagination');

    new Swiper(slider!, {
      loop: true,
      spaceBetween: 12,
      slidesPerView: 1,
      centeredSlides: true,

      navigation: {
        prevEl: prev,
        nextEl: next,
      },

      pagination: {
        el: pagination,
      },
    });
  });
};

const initZoom = () => {
  const sliders = document.querySelectorAll<HTMLDivElement>('.rooms__slider');
  sliders.forEach((slider) => {
    const slides = slider.querySelectorAll<HTMLDivElement>('.swiper-slide');

    const items = Array.from(slides)
      .filter((item) => !item.classList.contains('swiper-slide-duplicate'))
      .map((item) => ({ href: item.firstElementChild?.getAttribute('href') }));

    const glightbox = GLightbox({
      elements: items,
      loop: true,
    });

    slides.forEach((slide) => {
      slide.firstElementChild?.addEventListener('click', (event) => {
        event.preventDefault();
        glightbox.openAt(slide.dataset.swiperSlideIndex);
      });
    });
  });
};

const initMoreButton = () => {
  const button = document.querySelector<HTMLButtonElement>('.rooms__more');
  const rooms = document.querySelector<HTMLDivElement>('.rooms');

  button?.addEventListener('click', () =>
    rooms?.classList.add('rooms--active')
  );
};

const initBooking = () => {
  const bookingItems = document.querySelectorAll<HTMLDivElement>(
    '.rooms-booking'
  );

  bookingItems.forEach((item) =>
    ReactDOM.render(
      <React.StrictMode>
        <BookingProvider>
          <Booking room={item.dataset.room} />
        </BookingProvider>
      </React.StrictMode>,
      item
    )
  );
};

const rooms = {
  init: () => {
    initSliders();
    initZoom();
    initMoreButton();
    initBooking();
  },
};

export default rooms;
