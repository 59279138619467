import { FC, InputHTMLAttributes } from 'react';
import cn from 'classnames';
import styles from './input.module.scss';

interface Props extends InputHTMLAttributes<HTMLInputElement> {
  moduleClass?: string;
}

export const Input: FC<Props> = ({ moduleClass, ...rest }) => {
  const moduleStyles = cn(styles.module, moduleClass);

  return <input className={moduleStyles} {...rest} />;
};
