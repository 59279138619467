import {
  FC,
  FormEvent,
  MouseEvent,
  useCallback,
  useContext,
  useState,
} from 'react';
import { TextualBody } from '../../textual/textual-body';
import { Children } from '../../_common/children';
import { DatePicker } from '../../_common/date-picker';
import { Input } from '../../_common/input';
import { Modal2 } from '../../_common/modal2';
import { Policy } from '../../_common/policy';
import { Select } from '../../_common/select';
import { BookingContext } from '../booking-context';
import styles from './booking-body.module.scss';

declare const ym: any;

interface Props {
  kind: 'booking' | 'calculate';
}

export const BookingBody: FC<Props> = ({ kind }) => {
  const { state, dispatch } = useContext(BookingContext);

  const [isShowPolicy, setIsShowPolicy] = useState(false);

  const handleSubmit = async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    await fetch('/ajax/_forms.php', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        form: state.kind,
        room: state.room,
        arrival: state.arrival?.toLocaleDateString(),
        departure: state.departure?.toLocaleDateString(),
        adults: state.adults,
        children: state.children,
        name: state.name,
        phone: state.phone,
      }),
    }).then(() => {
      dispatch({ type: 'hideModal' });
      dispatch({ type: 'showThanks' });
    });

    ym(76527259, 'reachGoal', 'kick');
  };

  const handleConfirm = (event: MouseEvent<HTMLButtonElement>) => {
    if (!state.arrival) {
      dispatch({ type: 'showArrival' });
      event.preventDefault();
      return;
    }
    if (!state.departure) {
      dispatch({ type: 'showDeparture' });
      event.preventDefault();
      return;
    }
  };

  const handleArrivalToggle = () => {
    dispatch({ type: 'toggleArrival' });
  };

  const handleArrivalDayCLick = (day: Date) => {
    dispatch({ type: 'hideArrival' });
    dispatch({ type: 'setArrival', arrival: day });
  };

  const handleDepartureToggle = () => {
    dispatch({ type: 'toggleDeparture' });
  };

  const handleDepartureDayCLick = (day: Date) => {
    dispatch({ type: 'hideDeparture' });
    dispatch({ type: 'setDeparture', departure: day });
  };

  const adultsOptions = [...Array(10)].map((_, idx) => ({
    value: idx + 1,
    text: `${idx + 1}`,
  }));

  const childrenOptions = [...Array(11)].map((_, idx) => ({
    value: idx,
    text: `${idx}`,
  }));

  return (
    <>
      <div className={styles.module}>
        <h3 className={styles.title}>
          {kind === 'booking' ? 'Бронирование' : 'Расчет стоимости'}
        </h3>
        <form onSubmit={handleSubmit}>
          <DatePicker
            moduleClass={styles.arrival}
            isOpen={state.ui.arrivalIsShow}
            onToggle={handleArrivalToggle}
            placeholder="Дата заезда"
            value={state.arrival}
            onDayClick={handleArrivalDayCLick}
            disabledDaysBefore={new Date()}
          />
          <DatePicker
            moduleClass={styles.departure}
            isOpen={state.ui.departureIsShow}
            onToggle={handleDepartureToggle}
            placeholder="Дата отъезда"
            value={state.departure}
            onDayClick={handleDepartureDayCLick}
            disabledDaysBefore={state.arrival || new Date()}
          />
          <Select
            moduleClass={styles.adults}
            name="Кол-во взрослых"
            options={adultsOptions}
            value={state.adults}
            onChange={(event) =>
              dispatch({ type: 'setAdults', adults: +event.target.value })
            }
          />

          <Select
            moduleClass={styles.children}
            name="Кол-во детей"
            options={childrenOptions}
            value={state.children.length}
            onChange={(event) =>
              dispatch({ type: 'setChildren', children: +event.target.value })
            }
          />

          {state.children.length > 0 && (
            <Children
              isOpen={state.ui.childrenIsShow}
              onToggle={() => dispatch({ type: 'toggleChildren' })}
              children={state.children}
              onChangeChild={(value, idx) =>
                dispatch({ type: 'changeChild', value, idx })
              }
            />
          )}
          <Input
            moduleClass={styles.name}
            placeholder="Ваше имя"
            value={state.name}
            onChange={(event) =>
              dispatch({ type: 'setName', name: event.target.value })
            }
          />
          <Input
            moduleClass={styles.phone}
            placeholder="Номер телефона"
            type="tel"
            pattern="\d+"
            required
            value={state.phone}
            onChange={(event) =>
              dispatch({ type: 'setPhone', phone: event.target.value })
            }
          />
          <Policy
            moduleClass={styles.policy}
            onButtonClick={() => setIsShowPolicy(true)}
          />
          <button className={styles.confirm} onClick={handleConfirm}>
            Отправить
          </button>
        </form>
      </div>
      <Modal2
        isShow={isShowPolicy}
        onClose={useCallback(() => setIsShowPolicy(false), [])}
      >
        <TextualBody type="policy" />
      </Modal2>
    </>
  );
};
