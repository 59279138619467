import { FC, useContext } from 'react';
import { DatePicker } from '../../_common/date-picker';
import { QuizContext } from '../quiz-context';
import styles from './step-one.module.scss';

export const StepOne: FC = () => {
  const { state, dispatch } = useContext(QuizContext);

  const handleArrivalToggle = () => {
    dispatch({ type: 'toggleArrival' });
  };

  const handleArrivalDayCLick = (day: Date) => {
    dispatch({ type: 'hideArrival' });
    dispatch({ type: 'setArrival', arrival: day });
  };

  const handleDepartureToggle = () => {
    dispatch({ type: 'toggleDeparture' });
  };

  const handleDepartureDayCLick = (day: Date) => {
    dispatch({ type: 'hideDeparture' });
    dispatch({ type: 'setDeparture', departure: day });
  };

  return (
    <div className={styles.module}>
      <DatePicker
        moduleClass={styles.start}
        isOpen={state.ui.arrivalIsShow}
        onToggle={handleArrivalToggle}
        placeholder="Дата заезда"
        value={state.arrival}
        onDayClick={handleArrivalDayCLick}
        disabledDaysBefore={new Date()}
      />
      <DatePicker
        isOpen={state.ui.departureIsShow}
        onToggle={handleDepartureToggle}
        placeholder="Дата отъезда"
        value={state.departure}
        onDayClick={handleDepartureDayCLick}
        disabledDaysBefore={state.arrival || new Date()}
      />
    </div>
  );
};
