import { FC, SelectHTMLAttributes } from 'react';
import cn from 'classnames';
import styles from './select.module.scss';

interface Props extends SelectHTMLAttributes<HTMLSelectElement> {
  options: { value: number | string; text: string }[];
  name?: string;
  moduleClass?: string;
  isMini?: boolean;
}

export const Select: FC<Props> = ({
  name,
  options,
  moduleClass,
  isMini,
  ...rest
}) => {
  const moduleStyles = cn(styles.module, moduleClass, {
    [styles.mini]: isMini,
  });

  return (
    <label className={moduleStyles}>
      <span className={styles.name}>{name}</span>
      <select className={styles.select} {...rest}>
        {options.map((item, idx) => (
          <option key={idx} value={item.value}>
            {item.text}
          </option>
        ))}
      </select>
    </label>
  );
};
