import React from 'react';
import ReactDOM from 'react-dom';
import { Callback } from '../components/callback';

const initCallback = () => {
  ReactDOM.render(
    <React.StrictMode>
      <Callback type="question" />
    </React.StrictMode>,
    document.getElementById('question-callback')
  );
};

const question = {
  init: () => {
    initCallback();
  },
};

export default question;
