import { FC, useRef } from 'react';
import { createPortal } from 'react-dom';
import styles from './children.module.scss';
import { Dropdown } from './dropdown';

const portalEl = document.getElementById('portal');

interface Props {
  isOpen: boolean;
  children: number[];
  onToggle: () => void;
  onChangeChild: (value: number, idx: number) => void;
  inQuiz?: boolean;
}

export const Children: FC<Props> = ({
  isOpen,
  children,
  onToggle,
  onChangeChild,
  inQuiz,
}) => {
  const faceRef = useRef<HTMLDivElement>(null);

  return (
    <>
      <div className={styles.module} onClick={onToggle} ref={faceRef}>
        <div className={styles.label}>Возраст детей</div>
        <div className={styles.values}>
          {children.filter((_, idx) => idx < 4).join(', ')}
          {children.length > 4 && ', ...'}
        </div>
      </div>
      {portalEl &&
        isOpen &&
        createPortal(
          <Dropdown
            faceRef={faceRef}
            children={children}
            onOutsideClick={() => onToggle()}
            onChangeChild={onChangeChild}
            inQuiz={inQuiz}
          />,
          portalEl
        )}
    </>
  );
};
