import { FC, FormEvent, useCallback, useContext } from 'react';
import { Modal } from '../_common/modal';
import { Modal2 } from '../_common/modal2';
import { Thanks } from '../_common/thanks';
import { QuizContext } from './quiz-context';
import styles from './quiz.module.scss';
import { StepOne } from './step-one';
import { StepThree } from './step-three';
import { StepTwo } from './step-two';

declare const ym: any;

export const Quiz: FC = () => {
  const { state, dispatch } = useContext(QuizContext);

  const handleSubmit = async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    await fetch('/ajax/_forms.php', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        form: 'quiz',
        arrival: state.arrival?.toLocaleDateString(),
        departure: state.departure?.toLocaleDateString(),
        adults: state.adults,
        children: state.children,
        name: state.name,
        phone: state.phone,
      }),
    }).then(() => {
      dispatch({ type: 'showThanks' });
    });

    ym(76527259, 'reachGoal', 'kick');
  };

  const lastStep = 3;

  return (
    <>
      <form className={styles.module} onSubmit={handleSubmit}>
        <p className={styles.title}>Расчет стоимости</p>
        <p className={styles.steps}>Шаг {state.ui.currentStep} из 3</p>

        <div className={styles.slider}>
          <div
            className={styles.wrapper}
            style={{
              transform: `translateX(${(state.ui.currentStep - 1) * -100}%)`,
            }}
          >
            <StepOne />
            <StepTwo />
            <StepThree />
          </div>
        </div>

        <div className={styles.buttons}>
          {state.ui.currentStep > 1 && state.ui.currentStep < lastStep && (
            <button
              className={styles.prev}
              type="button"
              onClick={() => dispatch({ type: 'prevStep' })}
            />
          )}
          {state.ui.currentStep < lastStep && (
            <button
              className={styles.next}
              type="button"
              onClick={() => {
                if (state.ui.currentStep === 1 && !state.arrival) {
                  dispatch({ type: 'showArrival' });
                  return;
                }
                if (state.ui.currentStep === 1 && !state.departure) {
                  dispatch({ type: 'showDeparture' });
                  return;
                }
                dispatch({ type: 'nextStep' });
              }}
            >
              Далее
            </button>
          )}

          {state.ui.currentStep === lastStep && (
            <button className={styles.confirm}>Отправить</button>
          )}
        </div>
      </form>
      <Modal2
        isShow={state.ui.thanksIsShow}
        onClose={useCallback(() => dispatch({ type: 'hideThanks' }), [
          dispatch,
        ])}
      >
        <Thanks />
      </Modal2>
    </>
  );
};
