import React from 'react';
import ReactDOM from 'react-dom';
import { Booking } from '../components/booking';
import { BookingProvider } from '../components/booking/booking-context';

const initBooking = () => {
  ReactDOM.render(
    <React.StrictMode>
      <BookingProvider>
        <Booking />
      </BookingProvider>
    </React.StrictMode>,
    document.getElementById('screen-booking')
  );
};

const screen = {
  init: () => {
    initBooking();
  },
};

export default screen;
