import { FC, useCallback, useState } from 'react';
import { useCookie } from '../../hooks/useCookie';
import { TextualBody } from '../textual/textual-body';
import { Modal } from '../_common/modal';
import styles from './cookie.module.scss';

export const Cookie: FC = () => {
  const [isShow, setIsShow] = useState(true);
  const [cookie, updateCookie] = useCookie('cookie_notification');

  const [isShowPolicy, setIsShowPolicy] = useState(false);

  const fn = useCallback(() => setIsShowPolicy(false), []);

  return !cookie && isShow ? (
    <>
      <div className={styles.module}>
        <button className={styles.close} onClick={() => setIsShow(false)} />
        <p className={styles.text}>
          <span>
            Наш сайт использует файлы Cookie. Продолжая пользоваться сайтом, вы
            соглашаетесь на использование нами ваших файлов Cookie
          </span>{' '}
          <button
            className={styles.detail}
            onClick={() => setIsShowPolicy(true)}
          >
            Подронее
          </button>
        </p>
        <button
          className={styles.confirm}
          onClick={() => updateCookie('confirmed', 365)}
        >
          Хорошо, спасибо
        </button>
      </div>
      <Modal isShow={isShowPolicy} onClose={fn}>
        <TextualBody type="policy" />
      </Modal>
    </>
  ) : null;
};
