import React from 'react';
import ReactDOM from 'react-dom';
import { Callback } from '../components/callback';
import { Cookie } from '../components/cookie';
import { Textual } from '../components/textual';

const initCallback = () => {
  ReactDOM.render(
    <React.StrictMode>
      <Callback type="footer" />
    </React.StrictMode>,
    document.getElementById('footer-callback')
  );
};

const initCookie = () => {
  ReactDOM.render(
    <React.StrictMode>
      <Cookie />
    </React.StrictMode>,
    document.getElementById('cookie')
  );
};

const initTextual = () => {
  ReactDOM.render(
    <React.StrictMode>
      <Textual />
    </React.StrictMode>,
    document.getElementById('footer-textual')
  );
};

const footer = {
  init: () => {
    initCallback();
    initCookie();
    initTextual();
  },
};

export default footer;
