import { FC, useEffect, useState } from 'react';
import { createPortal } from 'react-dom';
import cn from 'classnames';
import styles from './modal2.module.scss';

const portalEl = document.getElementById('portal-2');

interface Props {
  isShow: boolean;
  onClose: () => void;
}

export const Modal2: FC<Props> = ({ isShow, onClose, children }) => {
  const [isRender, setIsRender] = useState(isShow);

  const moduleStyles = cn(styles.module, {
    [styles.fadeIn]: isShow,
    [styles.fadeOut]: !isShow,
  });

  useEffect(() => {
    if (isShow) {
      setIsRender(true);
    }
  }, [isShow]);

  useEffect(() => {
    if (isRender) {
      portalEl?.classList.add('overlay');
    } else {
      portalEl?.classList.remove('overlay');
    }

    const handleOverlayClick = (event: MouseEvent) => {
      if (event.target === event.currentTarget) {
        onClose();
      }
    };

    portalEl?.addEventListener('click', handleOverlayClick);

    return () => portalEl?.removeEventListener('click', handleOverlayClick);
  }, [isRender, onClose]);

  const handleAnimationEnd = () => {
    if (!isShow) {
      setIsRender(false);
    }
  };

  return portalEl && isRender
    ? createPortal(
        <div className={moduleStyles} onAnimationEnd={handleAnimationEnd}>
          <img className={styles.bg} src="/images/modal-bg.svg" alt="bg" />
          <button className={styles.close} onClick={() => onClose()} />
          {children}
        </div>,
        portalEl
      )
    : null;
};
