import { FC, useEffect, useRef } from 'react';
import styles from './textual-body.module.scss';

const policyText = document.getElementById('policy-text');
const rulesText = document.getElementById('rules-text');
const cookieText = document.getElementById('cookie-text');

interface Props {
  type: 'policy' | 'rules' | 'cookie';
}

export const TextualBody: FC<Props> = ({ type }) => {
  const contentRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (!contentRef.current) {
      return;
    }
    if (type === 'policy' && policyText) {
      contentRef.current.innerHTML = policyText.innerHTML;
    }
    if (type === 'rules' && rulesText) {
      contentRef.current.innerHTML = rulesText.innerHTML;
    }
    if (type === 'cookie' && cookieText) {
      contentRef.current.innerHTML = cookieText.innerHTML;
    }
  }, [type]);

  const titleText = {
    policy: 'Политика конфиденциальности',
    rules: 'Пользовательское соглашение',
    cookie: 'Политика использования cookie',
  };

  return (
    <div className={styles.module}>
      <h3 className={styles.title}>{titleText[type]}</h3>
      <div className={styles.content} ref={contentRef}></div>
    </div>
  );
};
