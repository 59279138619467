import { FC, FormEvent, useCallback, useState } from 'react';
import { TextualBody } from '../../textual/textual-body';
import { Input } from '../../_common/input';
import { Modal2 } from '../../_common/modal2';
import { Policy } from '../../_common/policy';

import styles from './callback-body.module.scss';

declare const ym: any;

interface Props {
  onSend: () => void;
}

export const CallbackBody: FC<Props> = ({ onSend }) => {
  const [name, setName] = useState('');
  const [phone, setPhone] = useState('');

  const [isShowPolicy, setIsShowPolicy] = useState(false);

  const handleSubmit = async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    await fetch('/ajax/_forms.php', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ form: 'callback', name, phone }),
    });

    ym(76527259, 'reachGoal', 'kick');

    onSend();
  };

  return (
    <>
      <div className={styles.module}>
        <h3 className={styles.title}>Заказать звонок</h3>
        <form onSubmit={handleSubmit}>
          <Input
            moduleClass={styles.name}
            placeholder="Ваше имя"
            value={name}
            onChange={(event) => setName(event.target.value)}
          />
          <Input
            moduleClass={styles.phone}
            placeholder="Номер телефона"
            type="tel"
            pattern="\d+"
            required
            value={phone}
            onChange={(event) => setPhone(event.target.value)}
          />
          <Policy
            moduleClass={styles.policy}
            onButtonClick={() => setIsShowPolicy(true)}
          />
          <button className={styles.confirm}>Отправить</button>
        </form>
      </div>
      <Modal2
        isShow={isShowPolicy}
        onClose={useCallback(() => setIsShowPolicy(false), [])}
      >
        <TextualBody type="policy" />
      </Modal2>
    </>
  );
};
