import { createContext, Dispatch, FC, useReducer } from 'react';
import { bookingReducer } from './booking-reducer';
import { Action, BookingState } from './types';

const initialState: BookingState = {
  kind: null,
  room: null,
  arrival: null,
  departure: null,
  adults: 2,
  children: [],
  name: '',
  phone: '',
  ui: {
    modalIsShow: false,
    arrivalIsShow: false,
    departureIsShow: false,
    childrenIsShow: false,
    thanksIsShow: false,
  },
};

export const BookingContext = createContext<{
  state: BookingState;
  dispatch: Dispatch<Action>;
}>({ state: initialState, dispatch: () => null });

export const BookingProvider: FC = ({ children }) => {
  const [state, dispatch] = useReducer(bookingReducer, initialState);

  return (
    <BookingContext.Provider value={{ state, dispatch }}>
      {children}
    </BookingContext.Provider>
  );
};
