const initMoreButton = () => {
  const button = document.querySelector<HTMLButtonElement>('.services__more');
  const services = document.querySelector<HTMLDivElement>('.services');

  button?.addEventListener('click', () =>
    services?.classList.add('services--active')
  );
};

const services = {
  init: () => {
    initMoreButton();
  },
};

export default services;
