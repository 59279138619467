import { FC, useContext } from 'react';
import { Children } from '../../_common/children';
import { Select } from '../../_common/select';
import { QuizContext } from '../quiz-context';
import styles from './step-two.module.scss';

export const StepTwo: FC = () => {
  const { state, dispatch } = useContext(QuizContext);

  const adultsOptions = [...Array(10)].map((_, idx) => ({
    value: idx + 1,
    text: `${idx + 1}`,
  }));

  const childrenOptions = [...Array(11)].map((_, idx) => ({
    value: idx,
    text: `${idx}`,
  }));

  return (
    <div className={styles.module}>
      <Select
        moduleClass={styles.adults}
        name="Кол-во взрослых"
        options={adultsOptions}
        value={state.adults}
        onChange={(event) =>
          dispatch({ type: 'setAdults', adults: +event.target.value })
        }
      />

      <Select
        name="Кол-во детей"
        options={childrenOptions}
        value={state.children.length}
        onChange={(event) =>
          dispatch({ type: 'setChildren', children: +event.target.value })
        }
      />

      {state.children.length > 0 && (
        <Children
          isOpen={state.ui.childrenIsShow}
          onToggle={() => dispatch({ type: 'toggleChildren' })}
          children={state.children}
          onChangeChild={(value, idx) =>
            dispatch({ type: 'changeChild', value, idx })
          }
          inQuiz
        />
      )}
    </div>
  );
};
