import { createContext, Dispatch, FC, useReducer } from 'react';
import { quizReducer } from './quiz-reducer';
import { Action, QuizState } from './types';

const initialState: QuizState = {
  arrival: null,
  departure: null,
  adults: 2,
  children: [],
  name: '',
  phone: '',
  ui: {
    currentStep: 1,
    arrivalIsShow: false,
    departureIsShow: false,
    childrenIsShow: false,
    thanksIsShow: false,
  },
};

export const QuizContext = createContext<{
  state: QuizState;
  dispatch: Dispatch<Action>;
}>({ state: initialState, dispatch: () => null });

export const QuizProvider: FC = ({ children }) => {
  const [state, dispatch] = useReducer(quizReducer, initialState);

  return (
    <QuizContext.Provider value={{ state, dispatch }}>
      {children}
    </QuizContext.Provider>
  );
};
